export const communityContent = [
  {
    title: 'Title',
    host: 'Melissa',
    hostDescription: 'Host of “Gardening 101”',
    profileImage: '/moments/images/moments-user.png',
    mainImage: '/moments/images/moments-main.png',
    shortDescription: (
      <div>
        <p>
          “Last May, I had an amazing time walking and talking. I met a new friend, Amanda! We now
          co-host a gardening experience!”
        </p>
      </div>
    ),
    longDescription: (
      <div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet massa auctor,
          blandit mi vel, varius diam. Etiam cursus varius rhoncus. 
        </p>
        <p>
          Aliquam mollis semper odio, id scelerisque orci faucibus feugiat. Vivamus eget dictum
          eros. Mauris a condimentum orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Cras sit amet massa auctor, blandit mi vel, varius diam. Etiam cursus varius rhoncus. 
        </p>
        <p>
          Cras sit amet massa auctor, blandit mi vel, varius diam. Etiam cursus varius rhoncus. 
        </p>
      </div>
    ),
  },
  // another one, sample content that's different from the first one
  {
    title: 'Singing Class',
    host: 'Jane',
    hostDescription: 'Host of “Singing Class”',
    profileImage: '/moments/images/moments-user.png',
    mainImage: '/moments/images/moments-main.png',
    shortDescription: (
      <div>
        <p>“We had a great time singing together. We sang our hearts out and had a blast!”</p>
      </div>
    ),
    longDescription: (
      <div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet massa auctor,
          blandit mi vel, varius diam. Etiam cursus varius rhoncus. 
        </p>
        <p>Aliquam mollis semper odio, id scelerisque</p>
        <p>Cras sit amet massa auctor, blandit mi vel, varius diam. Etiam cursus varius rhoncus.</p>
      </div>
    ),
  },
];
