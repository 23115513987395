'use client';

import { Container, List, ListItem, Typography, styled } from '@mui/material';
import {
  MomentsCommunityStepper,
  Slide,
} from './components/moments-community-stepper/moments-community-stepper';
import { communityContent } from './community-content';
import { HomepageContent } from '../../lib/fetch-content';
import { sanityImageUrlFor } from '@/content/sanity/sanity-image-url-for/sanity-image-url-for';
import { PortableText } from '@portabletext/react';

const Title = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  font-size: 54px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    font-size: 44px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  margin: 0 auto !important;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: auto;
    padding: 16px;
  }
`;

interface ContentProps {
  content: any;
}

function Content({ content }: ContentProps) {
  return (
    <PortableText
      value={content}
      components={{
        block: {
          h1: ({ children }) => <Typography variant="h1">{children}</Typography>,
          h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
          h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
          h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
          h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
          h6: ({ children }) => <Typography variant="h6">{children}</Typography>,
          normal: ({ children }) => <Typography>{children}</Typography>,
        },
        list: ({ children }) => <List>{children}</List>,
        listItem: ({ children }) => <ListItem>{children}</ListItem>,
      }}
    />
  );
}

interface CommunityProps {
  homepageContent: HomepageContent;
}

export function Community({ homepageContent }: CommunityProps) {
  const slides: Slide[] =
    homepageContent?.communityMoments.map(moment => ({
      host: moment.host,
      shortDescription: <Content content={moment.shortDescription} />,
      longDescription: <Content content={moment.longDescription} />,
      mainImage: sanityImageUrlFor(moment.mainImage).width(1200).height(800).url(),
      profileImage: sanityImageUrlFor(moment.profileImage).width(300).height(300).url(),
      hostDescription: moment.hostDescription,
      title: moment.title,
    })) ?? [];

  return (
    <StyledContainer>
      <Title>Moments from our community</Title>
      <MomentsCommunityStepper slides={slides} />
    </StyledContainer>
  );
}
