'use client';

import { theme } from '@/app/common/providers/theme-provider/theme';
import { BorderedTransparentButton } from '@/components/button/button';
import { Container, Stack, styled, useMediaQuery } from '@mui/material';
import Image from 'next/image';
import { ReactNode } from 'react';
import MomentsText from './components/moments-text/moments-text';
import { Constellation } from './components/constellation/constellation';

const OuterStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 40px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: center;
  }
`;

const InnerStack = styled(Stack)`
  width: 50%;
  justify-content: center;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 0px 0px;
  }
`;
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledButton = styled(BorderedTransparentButton)`
  width: max-content;
  color: #fff3dccc;
`;

export const momentsExplainerContent = {
  title: 'Curiko is made of moments',
  text: (
    <>
      <p>
        “Moments” are ephemeral, fleeting sparks of connection— to yourself, to others, to land, to
        something greater than yourself.
      </p>
      <p>
        Curiko experiences are full of opportunities to have and share different kinds of moments.
      </p>
    </>
  ),
  image: '/moments/images/moments-diagram.png',
};

interface MomentsProps {
  title?: ReactNode;
  text?: ReactNode;
  image?: string;
}

export function Moments({
  title = momentsExplainerContent.title,
  text = momentsExplainerContent.text,
  image = momentsExplainerContent.image,
}: MomentsProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {/* <MomentsDiv> */}
      {/* <StyledContainer> */}
      <Container sx={{ mx: 'auto' }}>
        <OuterStack>
          {isMobile && (
            <InnerStack>
              <Constellation />
            </InnerStack>
          )}
          <InnerStack>
            <MomentsText title={title} text={text} />
            <StyledButton>Learn more</StyledButton>
          </InnerStack>
          {!isMobile && (
            <InnerStack>
              <Constellation />
            </InnerStack>
          )}
        </OuterStack>
      </Container>
      {/* </StyledContainer> */}
      {/* </MomentsDiv> */}
    </>
  );
}
