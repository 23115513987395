import { BorderedTransparentButton } from '@/components/button/button';
import { styled } from '@mui/material';

const StyledButton = styled(BorderedTransparentButton)`
  border: 1px solid #fff3dccc;
  height: auto;
  padding: 8px 12px;
  gap: 10px;
  border-radius: 24px;
  justify-content: row;
  min-width: 130px;
`;

export default function MomentsCommunityReadMoreButton() {
  return <StyledButton>Read More</StyledButton>;
}
