'use client';

import { Container, Typography, styled } from '@mui/material';
import WhatIsCurikoStepper from './components/what-is-curiko-stepper/what-is-curiko-stepper';

const WhatTitle = styled(Typography)`
  color: white;
  font-family: var(--font-cooper-light), sans-serif;
  font-weight: 400;
  font-size: 54px;
  text-align: center;
  margin-bottom: 100px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-family: var(--font-cooper-light), sans-serif;
    font-size: 44px;
    font-weight: 400;
    line-height: 52.79px;
    text-align: center;
    margin-bottom: 50px;
  }
`;

export const What = () => {
  return (
    <Container>
      <WhatTitle>What is Curiko?</WhatTitle>
      <WhatIsCurikoStepper />
    </Container>
  );
};

export default What;
