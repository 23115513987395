import { Global, css } from '@emotion/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Stack, styled, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import Steps from './components/steps/steps';
import WhatIsCurikoImage from './components/what-is-curiko-image/what-is-curiko-image';
import { stepsContent } from './components/steps-content/steps-content';

const OuterStack = styled(Stack)`
  width: 100%;
  min-height: 600px;
  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px !important;
  }
`;
const InnerStackGradient = styled(Stack)<{ customBorderColor: string }>`
  width: 50%;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(37, 22, 40, 0.7) 0%, rgba(0, 0, 0, 0.7) 99.98%);
  border-radius: 24px;
  padding: 36px 48px 36px 48px;
  border-left: 8px solid;
  border-color: ${({ customBorderColor }) => customBorderColor};
  transition: border-color 0.5s;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    border-left: none;
    border-top: 8px solid;
    padding: 36px 24px 36px 24px;
    margin-bottom: 20px;
    border-color: ${({ customBorderColor }) => customBorderColor};
  }
`;

const InnerStack = styled(Stack)`
  width: 50%;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const CustomMobileStepper = styled(MobileStepper)`
  width: 300px;
  background-color: transparent;
  align-self: flex-start;
  max-height: 200;
  padding: 0;
`;

const FadeWrapper = styled('div')<{ isFading: boolean }>`
  transition: opacity 1s;
  opacity: ${({ isFading }) => (isFading ? 0 : 1)};
`;

export default function WhatIsCurikoStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isFading, setIsFading] = React.useState(false);
  const fadeTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const maxStep = stepsContent.length - 1;

  React.useEffect(() => {
    return () => {
      if (fadeTimer.current) {
        clearTimeout(fadeTimer.current);
      }
    };
  }, []);

  const handleNext = () => {
    setIsFading(true);
    fadeTimer.current = setTimeout(() => {
      setActiveStep(prev => (prev !== maxStep ? prev + 1 : 0));
      fadeTimer.current = setTimeout(() => {
        setIsFading(false);
      }, 100);
    }, 1000);
  };

  const handleBack = () => {
    setIsFading(true);
    fadeTimer.current = setTimeout(() => {
      setActiveStep(prev => (prev !== 0 ? prev - 1 : maxStep));
      fadeTimer.current = setTimeout(() => {
        setIsFading(false);
      }, 100);
    }, 1000);
  };

  const handleDotClick = (index: number) => {
    setIsFading(true);
    fadeTimer.current = setTimeout(() => {
      setActiveStep(index);
      fadeTimer.current = setTimeout(() => {
        setIsFading(false);
      }, 100);
    }, 1000);
  };

  return (
    <>
      <Global
        styles={css`
          .MuiMobileStepper-dot {
            background-color: #f9c8b7 !important;
            width: 12px !important;
            height: 12px !important;
            cursor: pointer;
          }
          .MuiMobileStepper-dotActive {
            background-color: #fff3dc !important;
            width: 12px !important;
            height: 12px !important;
            box-shadow: 0 0 15px #fff3dc !important;
          }
          .MuiMobileStepper-dots {
            padding: 0;
            width: 200px;
            justify-content: space-around !important;
          }
        `}
      />
      <OuterStack direction="row">
        {isMobile && (
          <InnerStack>
            <WhatIsCurikoImage activeStep={activeStep} />
          </InnerStack>
        )}
        <InnerStackGradient customBorderColor={stepsContent[activeStep].color}>
          <FadeWrapper isFading={isFading}>
            <Steps step={activeStep} />
          </FadeWrapper>
          <CustomMobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext}>
                {theme.direction === 'rtl' ? (
                  <ArrowBackIcon sx={{ color: '#FFF3DC' }} />
                ) : (
                  <ArrowForwardIcon sx={{ color: '#FFF3DC' }} />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack}>
                {theme.direction === 'rtl' ? (
                  <ArrowForwardIcon sx={{ color: '#FFF3DC' }} />
                ) : (
                  <ArrowBackIcon sx={{ color: '#FFF3DC' }} />
                )}
              </Button>
            }
            onClick={e => {
              const target = e.target as HTMLElement;
              if (!target.className.includes('MuiMobileStepper-dot')) {
                return;
              }
              const index = Array.from(target.parentElement?.children || []).indexOf(target);
              handleDotClick(index);
            }}
          />
        </InnerStackGradient>
        {!isMobile && (
          <InnerStack>
            <FadeWrapper isFading={isFading}>
              <WhatIsCurikoImage activeStep={activeStep} />
            </FadeWrapper>
          </InnerStack>
        )}
      </OuterStack>
    </>
  );
}
