import { styled } from '@mui/material';
import Image, { ImageProps } from 'next/image';

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 25px;
`;
export default function MomentsCommunityImage(props: ImageProps) {
  return <StyledImage width={650} height={650} {...props} />;
}
