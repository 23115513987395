import { Global, css } from '@emotion/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Stack, styled } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { communityContent } from '../../community-content';
import MomentsCommunityImage from './components/moments-community-image/moments-community-image';

import { ReactNode } from 'react';
import MomentsSteps from './components/moments-steps/moments-steps';

const OuterStack = styled(Stack)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px !important;
  }
`;

const InnerStack = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative; /* Required for positioning the pseudo-elements */
  border-width: 3px 0 3px 0; /* Top and bottom borders */
  border-style: solid;
  border-image: linear-gradient(
      270deg,
      rgba(198, 153, 187, 0) 0%,
      rgba(198, 153, 187, 0.5) 50%,
      rgba(198, 153, 187, 0) 100%
    )
    1;
  padding: 20px 0; /* Adds space between borders and content */
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px !important;
  }
`;

const TopGradient = styled('div')`
  height: 100px;
  margin-top: -50px;
  width: 100%;
  background: radial-gradient(
    50% 50% at 50.05% 100%,
    rgba(198, 153, 187, 0.15) 0%,
    rgba(198, 153, 187, 0) 100%
  );
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 50px;
    margin-top: -25px;
    background: radial-gradient(
      50% 50% at 50.05% 100%,
      rgba(198, 153, 187, 0.15) 0%,
      rgba(198, 153, 187, 0) 100%
    );
  }
`;

const BottomGradient = styled('div')`
  height: 100px;
  margin-bottom: -50px;
  width: 100%;
  background: radial-gradient(
    50% 50% at 50% 0%,
    rgba(198, 153, 187, 0.15) 0%,
    rgba(198, 153, 187, 0) 100%
  );
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 50px;
    margin-bottom: -25px;
    background: radial-gradient(
      50% 50% at 50% 0%,
      rgba(198, 153, 187, 0.15) 0%,
      rgba(198, 153, 187, 0) 100%
    );
  }
`;
const ImageStack = styled(Stack)`
  width: 60%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const TextStack = styled(Stack)`
  width: 40%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
const CustomMobileStepper = styled(MobileStepper)`
  width: 500px;
  background-color: transparent;
  align-self: center;
  max-height: 200px;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    padding-bottom: 20px;
  }
`;

export interface Slide {
  title: string;
  host: string;
  hostDescription: string;
  profileImage: string;
  mainImage: string;
  shortDescription: ReactNode;
  longDescription: ReactNode;
}

interface MomentsCommunityStepperProps {
  slides?: Slide[];
}

export function MomentsCommunityStepper({ slides = [] }: MomentsCommunityStepperProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isFading, setIsFading] = React.useState(false);
  const maxStep = communityContent.length - 1;

  const fadeTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    return () => {
      if (fadeTimer.current) {
        clearTimeout(fadeTimer.current);
      }
    };
  }, []);

  const activeSlide = slides[activeStep];

  const handleNext = () => {
    setIsFading(true);
    fadeTimer.current = setTimeout(() => {
      setActiveStep(prev => (prev === maxStep ? 0 : prev + 1));
      fadeTimer.current = setTimeout(() => {
        setIsFading(false);
      }, 100);
    }, 1000);
  };

  const handleBack = () => {
    setIsFading(true);
    fadeTimer.current = setTimeout(() => {
      setActiveStep(prev => (prev === 0 ? maxStep : prev - 1));
      fadeTimer.current = setTimeout(() => {
        setIsFading(false);
      }, 100);
    }, 1000);
  };

  const handleDotClick = (index: number) => {
    setIsFading(true);
    fadeTimer.current = setTimeout(() => {
      setActiveStep(index);
      fadeTimer.current = setTimeout(() => {
        setIsFading(false);
      }, 100);
    }, 1000);
  };

  if (!activeSlide) {
    return null;
  }

  return (
    <>
      <Global
        styles={css`
          .MuiMobileStepper-dot {
            background-color: #f9c8b7 !important;
            width: 12px !important;
            height: 12px !important;
          }
          .MuiMobileStepper-dotActive {
            background-color: #fff3dc !important;
            width: 12px !important;
            height: 12px !important;
            box-shadow: 0 0 15px #fff3dc !important;
          }
          .MuiMobileStepper-dots {
            padding: 0;
            width: 200px;
            justify-content: space-around !important;
          }
        `}
      />
      <OuterStack>
        <TopGradient />
        <InnerStack
          style={{
            transition: 'opacity 1s',
            opacity: isFading ? 0 : 1,
          }}
        >
          <ImageStack>
            <MomentsCommunityImage src={activeSlide.mainImage} alt={activeSlide.title} />
          </ImageStack>
          <TextStack>
            <MomentsSteps
              host={activeSlide.host}
              hostDescription={activeSlide.hostDescription}
              profileImageProps={{
                src: activeSlide.profileImage,
                alt: activeSlide.host,
              }}
              shortDescription={activeSlide.shortDescription}
            />
          </TextStack>
        </InnerStack>
        <BottomGradient />
        <CustomMobileStepper
          variant="dots"
          steps={maxStep + 1}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext}>
              {theme.direction === 'rtl' ? (
                <ArrowBackIcon sx={{ color: '#FFF3DC' }} />
              ) : (
                <ArrowForwardIcon sx={{ color: '#FFF3DC' }} />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack}>
              {theme.direction === 'rtl' ? (
                <ArrowForwardIcon sx={{ color: '#FFF3DC' }} />
              ) : (
                <ArrowBackIcon sx={{ color: '#FFF3DC' }} />
              )}
            </Button>
          }
          onClick={e => {
            console.log(e);
            const target = e.target as HTMLElement;
            if (!target.className.includes('MuiMobileStepper-dot')) {
              return;
            }
            const index = Array.from(target.parentElement?.children || []).indexOf(target);
            handleDotClick(index);
          }}
        />
      </OuterStack>
    </>
  );
}
