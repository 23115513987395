'use client';

import { Box, styled } from '@mui/material';
import Slide1 from './assets/const-1.png';
import Slide2 from './assets/const-2.png';
import Slide3 from './assets/const-3.png';
import Slide4 from './assets/const-4.png';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

const ConstellationWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    aspect-ratio: 1;
  }
`;

const Slide = styled(Image)<{ index: number; active: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: ${({ index }) => (index * 2).toString()};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 3s ease-in-out;
`;

export function Constellation() {
  const [activeSlide, setActiveSlide] = useState(1);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setActiveSlide(prev => (prev === 4 ? 1 : prev + 1));
      }, 4000);
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  return (
    <ConstellationWrapper>
      <Slide src={Slide1} alt="" index={1} active={activeSlide === 1} />
      <Slide src={Slide2} alt="" index={2} active={activeSlide === 2} />
      <Slide src={Slide3} alt="" index={3} active={activeSlide === 3} />
      <Slide src={Slide4} alt="" index={4} active={activeSlide === 4} />
    </ConstellationWrapper>
  );
}
