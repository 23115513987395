import { Stack, Typography, styled } from '@mui/material';
import { stepsContent } from '../steps-content/steps-content';

const Title = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Text = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
`;

export default function Steps({ step }: { step: number }) {
  const { title, text, color } = stepsContent[step];
  const [firstLetter, ...restOfTitle] = title.split(' ');

  return (
    <Stack sx={{ height: '100%' }}>
      <Title>
        <span style={{ color: 'white' }}>{firstLetter}</span>{' '}
        <span style={{ color }}>{restOfTitle.join(' ')}</span>
        <span style={{ color: 'white' }}>.</span>
      </Title>
      <Text>{text}</Text>
    </Stack>
  );
}
