export const stepsContent = [
  {
    title: 'A community',
    text: (
      <div>
        <p>Curiko is a place where everyone belongs.</p>
        <p>
          We are a constellation of people, with and without disabilities, that gather to connect,
          learn and grow.
        </p>
        <p>
          Our collective vision is to cross lines of difference through sharing moments of authentic
          connection. Moments that can be fleeting or long lasting.
        </p>
        <p>Our community is a place that embraces and values different lived experiences.</p>
      </div>
    ),
    image: '/what-is-curiko/images/community.png',
    color: '#84D18F',
  },
  {
    title: 'A resource',
    text: (
      <div>
        <p>
          Behind Curiko is a team of coaches who work with you to bust barriers that keep you from
          moving forward and curators who support you to share a passion with others.
        </p>
        <p>
          Whether it’s renewing a past interest or diving into a new one, broadening your social
          circle and perspectives or challenging existing ones, experiences offer moments to connect
          to yourself, new ideas, people, and places to find more moments that help us live well and
          with purpose.
        </p>
      </div>
    ),
    image: '/what-is-curiko/images/resource.png',
    color: '#DAA87A',
  },
  {
    title: 'A platform',
    text: (
      <div>
        <p>
          Curiko connects people! It functions as a platform where anyone can host experiences or go
          on experiences to both share and shift their perspectives.
        </p>
        <p>
          You get to choose! Unlike traditional volunteering or learning environments, it’s your
          choice how and when you’d like to be involved: create your own content, choose experiences
          that interest you and manage your own schedule.
        </p>
      </div>
    ),
    image: '/what-is-curiko/images/platform.png',
    color: '#B1C1BB',
  },
  {
    title: 'A prototype',
    text: (
      <div>
        <p>
          Curiko is the product of many years of design, research, and development. It is
          continuously growing and changing!
        </p>
        <p>
          It is co-designed, co-produced, and collectively governed. We are committed to not
          replicating existing systems and structures that dampen and oppress perspectives that
          challenge the status quo.
        </p>
        <p>
          We are actively learning, evolving and engaging with community members to adapt the
          platform and try new things.
        </p>
      </div>
    ),
    image: '/what-is-curiko/images/prototype.png',
    color: '#F4A7B9',
  },
];
