import { Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

const Title = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  font-size: 54px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    font-size: 44px;
  }
`;

const Text = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`;

interface MomentsTextProps {
  title: ReactNode;
  text: ReactNode;
}

export default function MomentsText({ title, text }: MomentsTextProps) {
  return (
    <>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </>
  );
}
