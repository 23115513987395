'use client';

import { Stack, styled } from '@mui/material';

const Background = styled(Stack)`
  background-color: black;
  width: 100%;
`;

export function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <Background
      py={8}
      direction={'column'}
      alignItems={'center'}
      sx={{
        gap: {
          xs: '40px',
          sm: '80px',
          md: '150px',
          lg: '200px',
        },
      }}
    >
      {children}
    </Background>
  );
}
