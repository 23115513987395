import { Container, Stack, Typography, styled } from '@mui/material';
import Image, { ImageProps } from 'next/image';
import { ReactNode } from 'react';
import MomentsCommunityReadMoreButton from '../moments-community-read-more-button/moments-community-read-more-button';

const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
  padding: 0 12px;
`;
const ShortDescriptionText = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffffcc;
`;
const HostNameText = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.46px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffffcc;
`;
const HostDescriptionText = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 18.77px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffffcc;
`;
const HostCardOuterStack = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 12px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
`;

const HostCardInnerStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 12px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0px;
  }
`;

const HostCardTextStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const HostImage = styled(Image)`
  border-radius: 50%;
`;

const ReadMoreButtonWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 12px;
  }
`;

interface MomentsStepsProps {
  shortDescription: ReactNode;
  host: ReactNode;
  profileImageProps: ImageProps;
  hostDescription: ReactNode;
}

export default function MomentsSteps({
  shortDescription,
  host,
  profileImageProps,
  hostDescription,
}: MomentsStepsProps) {
  return (
    <StyledContainer>
      <ShortDescriptionText>{shortDescription}</ShortDescriptionText>
      <HostCardOuterStack>
        <HostCardInnerStack>
          <HostImage
            width={52}
            height={52}
            {...profileImageProps}
            alt={profileImageProps.alt || ''}
          />
          <HostCardTextStack>
            <HostNameText>{host}</HostNameText>
            <HostDescriptionText>{hostDescription}</HostDescriptionText>
          </HostCardTextStack>
        </HostCardInnerStack>
        <ReadMoreButtonWrapper>
          <MomentsCommunityReadMoreButton />
        </ReadMoreButtonWrapper>
      </HostCardOuterStack>
    </StyledContainer>
  );
}
