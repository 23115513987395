import Image from 'next/image';
import ResourceSVG from 'public/what-is-curiko/svgs/resource.svg';
import PlatformSVG from 'public/what-is-curiko/svgs/platform.svg';
import PrototypeSVG from 'public/what-is-curiko/svgs/prototype.svg';
import CommunitySVG from 'public/what-is-curiko/svgs/community.svg';
import { styled } from '@mui/material';
import { stepsContent } from '../steps-content/steps-content';

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export default function WhatIsCurikoImage({ activeStep }: { activeStep: number }) {
  const imageSrc = stepsContent[activeStep].image;
  return (
    <>
      {/* {activeStep === 0 && <CommunitySVG alt="community" width={650} height={650} />}
          {activeStep === 1 && <ResourceSVG alt="resource" width={650} height={650} />}
          {activeStep === 2 && <PlatformSVG alt="platform" width={650} height={650} />}
          {activeStep === 3 && <PrototypeSVG alt="prototype" width={650} height={650} />} */}
      {activeStep === 0 && <StyledImage src={imageSrc} alt="community" width={650} height={650} />}
      {activeStep === 1 && <StyledImage src={imageSrc} alt="resource" width={650} height={650} />}
      {activeStep === 2 && <StyledImage src={imageSrc} alt="platform" width={650} height={650} />}
      {activeStep === 3 && <StyledImage src={imageSrc} alt="prototype" width={650} height={650} />}
    </>
  );
}
